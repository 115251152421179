import React from 'react';
import Content from './Content.js';
import {active_vm_src_override} from  '../activeVM.js';


// The URL will look like http://localhost:3000/#share/http://www.google.com

// and extract the "http://www.google.com" part.

const share_url = () => window.location.href.split('#share/').slice(1).join('#share/');


const share = () => {

  active_vm_src_override.set(share_url());

  return <div style={{flexGrow: 1}}>
      <Content/>
  </div>;

};
export default share