import { action, observable, makeObservable } from 'mobx';

class PrinterController {
  
  //@observable
  status = 'done';
  message = '';

  availableList = [];
  selectedList = []; 

  constructor() {
    makeObservable(this, {
      status: observable,
      message: observable,
      availableList: observable,
      selectedList: observable,
      scan: action.bound,
      updateSelectedPrinters: action.bound,
      syncSelectedListFromLocalStorage: action.bound,
    });
  }

  //@action 
  async scan() {
    try {
      this.status = 'scanning';
      this.message = 'Scanning available printers ...';
      
      this.availableList = [];
      const result = await window.api.scanPrinters();
      
      this.availableList = result.map(p => p.name);
      
      // remove unavailable printers in selectedList
      this.selectedList = this.selectedList.filter(p => this.availableList.includes(p));
      
      this.status = 'done';

    } catch (error) {
      this.status = 'failed';
      console.log(error);
    }
  }

  //@action
  updateSelectedPrinters() {
    const printerSelect = document.getElementById( "printerDevices" );
    let selectedPrinters = []; 

    for (let item of printerSelect.selectedOptions) { 
      // console.log(item.value); 
      selectedPrinters.push(item.value);
    }
    
    this.selectedList = selectedPrinters;
  }

  //@action
  syncSelectedListFromLocalStorage(persistedList) {
    this.selectedList = persistedList;
  }
}

export const printerController = new PrinterController();
