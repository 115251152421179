import { action, computed, when, observable, transaction, makeObservable } from 'mobx';

// This const must match with file electron/rpiClient/displayExecuter
const HDMI0 = 2;
const HDMI1 = 7;

class Display {
  id;
  currentModeId = -1;// the current active display settings mode
  currentPositionOption = -1;// the current position setting of HDMI1

  //@observable
  status = '';
  message = '';
  displayInfo = null;
  positionOptions = null;

  constructor(id) {
    if (!(id === HDMI0 || id === HDMI1))
      throw new Error("Not support display device with id " + id);

    this.id = id;
    makeObservable(this, {
      status: observable,
      message: observable,
      displayInfo: observable,
      positionOptions: observable,
      updateSelectedMode: action.bound,
      setDisplayInfo: action.bound,
      resetSelectedMode: action.bound,
      updateSelectedOption: action.bound,
      setPositionOptions: action.bound,
      selectedMode: computed,
      isAvailable: computed,
      selectedOption: computed,
      displayWidth: computed,
      displayHeight: computed,
      maxHeight: computed,
    });

  }

  //@action
  setDisplayInfo(info) {
    this.displayInfo = info;
    this.currentModeId = this.selectedMode.id;
  }

  //@action
  async updateSelectedMode(modeId) {
    try {
      const foundMode = this.displayInfo.modes.find(m => m.id == modeId);

      if (!foundMode) throw new Error("Not found mode with id " + modeId);

      transaction(() => {
        if (this.selectedMode)
          this.selectedMode.isSelected = false;

        foundMode.isSelected = true;
      });

    } catch (error) {
      console.log(error);
    }
  }

  //@action
  resetSelectedMode() {
    if (this.currentModeId >= 0)
      this.updateSelectedMode(this.currentModeId);

    if (this.currentPositionOption >= 0)
      this.updateSelectedOption(this.currentPositionOption);
  }

  //@action
  updateSelectedOption(id) {
    try {
      const foundOption = this.positionOptions.find(opt => opt.id == id);

      if (!foundOption) throw new Error("Not found option with id " + id);

      transaction(() => {
        if (this.selectedOption)
          this.selectedOption.isSelected = false;

        foundOption.isSelected = true;
      });

    } catch (error) {
      console.log(error);
    }
  }

  //@action
  setPositionOptions(positions) {
    this.positionOptions = positions;
    this.currentPositionOption = this.selectedOption.id;
  }

  //@computed
  get selectedOption() {
    return this.positionOptions !== null && this.positionOptions.find(opt => opt.isSelected);
  }

  //@computed
  get selectedMode() {
    return this.displayInfo !== null && this.displayInfo.modes.find(m => m.isSelected);
  }

  //@computed
  get isAvailable() {
    return this.displayInfo !== null && !!this.displayInfo.id;
  }

  //@computed
  get displayWidth() {
    return this.selectedMode.width / 10;
  }

  //@computed
  get displayHeight() {
    return this.selectedMode.height / 10;
  }

  //@computed
  get maxHeight() {
    return this.displayInfo.modes.length > 0 ? this.displayInfo.modes[0].height : 0;
  }
}


class DisplayController {

  title;
  currentAutoResolution = null;

  //@observable
  status = '';
  message = '';
  isFirstLoad = false;
  displayHDMI0 = null;
  displayHDMI1 = null;
  isAutoResolution = null;

  constructor() {
    this.title = "Display Settings"
    this.displayHDMI0 = new Display(HDMI0);
    this.displayHDMI1 = new Display(HDMI1);
    makeObservable(this, {
      status: observable,
      message: observable,
      isFirstLoad: observable,
      isAutoResolution: observable,
      loadDisplaysInfo: action.bound,
      loadCurrentDisplaySettings: action.bound,
      updateAutoResolution: action.bound,
      applyChanges: action.bound,
      illustrationHeight: computed,
    });

    // this.loadDisplaysInfo();
    when(
      () => this.isFirstLoad,
      () => this.loadDisplaysInfo()
    );
  }

  //@action
  async loadDisplaysInfo() {
    try {
      this.status = 'loading';

      const hdmi0Result = await window.api.getDisplayInfoById(HDMI0);
      this.displayHDMI0.setDisplayInfo(hdmi0Result);

      const hdmi1Result = await window.api.getDisplayInfoById(HDMI1);
      this.displayHDMI1.setDisplayInfo(hdmi1Result);

      //~ console.log(this.displayHDMI0.displayInfo);
      //~ console.log(this.displayHDMI1.displayInfo);

      if (this.displayHDMI0.isAvailable && this.displayHDMI1.isAvailable) {// dual monitors
        const hdmi1Position = await window.api.getPositionOfHDMI1();

        this.displayHDMI1.setPositionOptions([
          { id: 0, valueCSS: "flex-row-reverse", value: "LEFT", name: "Left of HDMI0", isSelected: hdmi1Position == "LEFT" },
          { id: 1, valueCSS: "flex-row", value: "RIGHT", name: "Right of HDMI0", isSelected: hdmi1Position == "RIGHT" },
          { id: 2, valueCSS: "flex-column-reverse", value: "TOP", name: "Top of HDMI0", isSelected: hdmi1Position == "TOP" },
          { id: 3, valueCSS: "flex-column", value: "BOTTOM", name: "Bottom of HDMI0", isSelected: hdmi1Position == "BOTTOM" },
        ]);
      }

      // load auto resolution config
      this.currentAutoResolution = await window.api.isAutoResolution();
      this.isAutoResolution = this.currentAutoResolution;

      this.status = 'done';
    } catch (error) {
      console.log(error);
      this.status = 'failed';
    }
  }

  //@action
  loadCurrentDisplaySettings() {
    // update isAutoResolution
    this.displayHDMI0.resetSelectedMode();
    this.displayHDMI1.resetSelectedMode();
    // reset to current auto resolution setting
    this.isAutoResolution = this.currentAutoResolution;
  }

  //@action
  updateAutoResolution(checked) {
    this.isAutoResolution = checked;
  }

  //@action
  async applyChanges() {
    if (this.isAutoResolution) {
      await window.api.setAutoResolution();
      return window.api.reboot();
    }

    // custom resolutions
    try {
      let result;

      if (this.displayHDMI0.isAvailable) {
        result = await window.api.setDisplay({ ...this.displayHDMI0.selectedMode, id: this.displayHDMI0.id });
        console.log(result);
        if (!result) throw new Error("Cannot update display settings of HDMI0");
      }

      if (this.displayHDMI1.isAvailable) {
        result = await window.api.setDisplay({ ...this.displayHDMI1.selectedMode, id: this.displayHDMI1.id });
        console.log(result);
        if(!result) throw new Error("Cannot update display settings of HDMI1");
        
        if(this.displayHDMI1.selectedOption &&
            String(this.displayHDMI1.selectedOption.id) !== String(this.displayHDMI1.currentPositionOption)){
          
          result = await window.api.setPositionOfHDMI1(this.displayHDMI1.selectedOption.value);
          console.log("Set new HDMI1 position: " + result);
          if (!result) throw new Error("Cannot update position settings of HDMI1");
        }
      }

      window.api.reboot();
    } catch (error) {
      console.log(error);
    }
  }

  //@computed
  get illustrationHeight() {
    let height = 10;
    if (this.displayHDMI1.isAvailable) height += this.displayHDMI1.maxHeight / 10;
    if (this.displayHDMI0.isAvailable) height += this.displayHDMI0.maxHeight / 10;

    return height;
  }
}

export const displayController = new DisplayController();
