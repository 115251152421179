import React from 'react';
import { observer } from 'mobx-react';
import { get_oauth_url } from '../auth.js';
import { is_whitelabel } from '../consts.js';
import { whitelabel_info } from '../whitelabel.js';


const log_in_text = is_whitelabel ? "Log in" : "Log in with V2";

const Login = observer(class extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: ''
		}

	}

	render() {

		return (

			<div className="the-login-section">
				<div className="form-wrap">
					<div className="logo-sec">
						<img
							className='tw-inline-flex'
							style={{

								// set custom logos to 150px instead of 100px default

								...(is_whitelabel && whitelabel_info.get() && whitelabel_info.get().logo_url && {
									maxWidth: '150px'
								})

							}}
							src={
								is_whitelabel ?
									(whitelabel_info.get() && whitelabel_info.get().logo_url) || "images/icon-whitelabel.png" :
									"images/logo.png"
							}
							alt=""
						/>
					</div>

					{(window.isElectron ?

						<a style={{
							textAlign: "center",
							display: "block",
							background: "#00438b",
							color: "white",
							padding: "10px",
							borderRadius: "10px"
						}}
							href="#."
							onClick={(e) => {
								e.preventDefault()
								window.api.open_link_external(get_oauth_url())
							}}>{log_in_text} <i style={{ marginLeft: '10px' }} className="fa fa-external-link-square" aria-hidden="true"></i></a>
						:

						<a style={{
							textAlign: "center",
							display: "block",
							background: "#00438b",
							color: "white",
							padding: "10px",
							borderRadius: "10px"
						}}
							href="#."
							onClick={(e) => {
								e.preventDefault()
								window.location.href = get_oauth_url()
							}}>{log_in_text} <i style={{ marginLeft: '10px' }} className="fa fa-external-link-square" aria-hidden="true"></i></a>

					)}

				</div>
			</div>

		);

	}

});


export default Login;
