import { action, computed, observable, makeObservable, toJS, observe, reaction } from 'mobx';
import { active_perm } from './activeVM.js';
import { permission_list, is_ad_user, ad_user_id } from './userData.js';
import axios from 'axios';
import poll from './util/poll';
import { vm_cache } from './components/util/WebconnectFrame.js';
import { getItem } from './storage.js';
import { web_api_entrypoint } from './consts.js';
import { poll_task } from './connectUtils.js';

const UMS_PORT = 5119
const UMS_WebRTC_PORT = 5135

class CameraController {

  firstLoad = true;// autoload 1st time
  title = "Choose your preferred camera";
  checkStreamStatus = null;

  NULL_DEVICE = { "id": -1, "name": "Choose your preferred camera" };

  static STREAM_STATUS = {
    NO_STREAM: 'no_stream',
    STOPPING_STREAM: 'stopping_stream',
    STARTING_STREAM: 'starting_stream',
    STREAMMING: 'streamming',
  }

  static TUNNEL_STATUS = {
    NO_TUNNEL: 'no_tunnel',
    STOPPING_TUNNEL: 'stopping_tunnel',
    STARTING_TUNNEL: 'starting_tunnel',
    TUNNELING: 'tunneling',
  }

  //@observable
  message = '';
  activeVM = null;

  status = 'done';
  availableDevices = [];
  selectedDevice = this.NULL_DEVICE;
  tunnels = {};
  // eslint-disable-next-line
  streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
  tunnelsStatus = {}; // status of tunnel for each vm {`${(adu|vmu)_id}_${vm_id}`: status}
  callingAPIs = {}; // tracking Redirect Camera API call {`${(adu|vmu)_id}_${vm_id}`: (true|false)}

  constructor() {
    makeObservable(this, {
      status: observable,
      message: observable,
      activeVM: observable,
      tunnels: observable,
      tunnelsStatus: observable,
      streamStatus: observable,
      availableDevices: observable,
      selectedDevice: observable,
      scan: action.bound,
      updateSelectedDevice: action.bound,
      syncSelectedDeviceFromLocalStorage: action.bound,
      controlByContext: action.bound,
      updateActiveVM: action.bound,
      updateTunnels: action.bound,
      toggleTunnel: action.bound,
      openVideoStreamSSHtunnel: action.bound,
      closeVideoStreamSSHtunnel: action.bound,
      preferredCamera: computed,
      isStreaming: computed,
      isRedirectionInProgress: computed,
      actionStatus: computed,
      iconStatus: computed,
      iconStyle: computed,
    });

    reaction(
      () => this.tunnels,
      tunnels => {
        if (this.isStreaming) {

          if (Object.keys(tunnels).length === 0) {
            console.log("There is no tunnel => stop camera")
            this.stopCamera()
          }

        } else if (Object.keys(tunnels).length > 0) { // no stream

          // reset state of all tunnels
          console.log("Tunnel(s) with no stream => closing all tunnels")
          Object.keys({ ...this.tunnelsStatus })
            .forEach(tunnelKey => this.closeVideoStreamSSHtunnel(tunnelKey))
        }
      }
    )

    reaction(
      () => this.isStreaming,
      isStreaming => {
        const selectedDeviceID = this.selectedDevice.id

        if (isStreaming && !this.checkStreamStatus) {

          console.log("Camera is streaming, start periodic check")
          this.checkStreamStatus = function timeout(setStreamStatusToNoStream) {

            setTimeout(async () => {
              if (await window.api.isLocalVideoStreaming(selectedDeviceID)) {
                checkToStopRedirection()
                timeout(setStreamStatusToNoStream)
              }
              else
                setStreamStatusToNoStream()
            }, 10000)
          }

          this.checkStreamStatus(
            // function setStreamStatusToNoStream
            () => this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM)

        } else if (!isStreaming && !!this.checkStreamStatus) {

          console.log("Camera did not streaming, stopping periodic check")
          this.checkStreamStatus = null

          console.log("Stopping stream app")
          this.stopCamera()

          // reset state of all tunnels
          console.log("Closing all tunnels")
          Object.keys({ ...this.tunnelsStatus })
            .forEach(tunnelKey => this.closeVideoStreamSSHtunnel(tunnelKey))
        }
      }
    )
  }

  isStreamPublished(tunnelKey) {
    if (!!tunnelKey) {
      const publishStreams = window.api.publishStreams()
      return publishStreams?.[tunnelKey]?.state === "connected"
    }
    return false
  }

  //@action get info need to establish SSH tunnel and API ip-camera
  async updateActiveVM(permId) {

    if (!permId) return;

    let rdp_params;
    let task_id;

    const pl = permission_list.get();
    const perm = pl[permId];

    if (!perm || !perm.vm.id) {
      this.activeVM = null;
      return;
    };

    try {
      if (is_ad_user.get()) {

        let q;

        if (perm.type === 'vmpool' || perm.type === 'vmpool-app') {
          q = `/api/advmpoolaccesss/${perm.id}/ssh_rdp_params`;
        } else {
          q = `/api/advmaccesss/${perm.id}/ssh_rdp_params`;
        }

        const data = (await axios({
          url: q,
          params: {
            ...(pl[permId].app && { app: pl[permId].app.id })
          }
        })).data;

        rdp_params = data;
        task_id = data.task && data.task.id;

        perm.last_vmpool_vm_id = data.vm;
        perm.last_vmpool_vmuser_id = data.vmuser;
        permission_list.set(pl);

      } else {

        const data = (await axios({
          url: `/api/permissions/${permId}/ssh_rdp_params`,
          params: {
          }
        })).data;

        rdp_params = data;
        task_id = data.task && data.task.id;

        perm.last_vmpool_vm_id = data.vm;
        perm.last_vmpool_vmuser_id = data.vmuser;
        permission_list.set(pl);

      }

      if (!rdp_params.vm)
        throw new Error("Invalid VM ID", rdp_params.vm)

      if (task_id)
        await poll_task(task_id)

    } catch (e) {
      console.error(`Failed to get RDP_PARAMS with PERM "${permId}": ${e}`);
      this.activeVM = null;
      return;
    }

    const adUserId = ad_user_id.get()
    const vmUserId = rdp_params.vmuser || (perm.vmuser && perm.vmuser.id)
    const cameraTunnelKey = adUserId ? `adu${adUserId}_${rdp_params.vm}` : `vmu${vmUserId}_${rdp_params.vm}`

    this.activeVM = { ...rdp_params, tunnelKey: cameraTunnelKey, description: perm.vm.descriptor, vm_id: rdp_params.vm, sid: rdp_params.sid };

    /* activeVM format
     * {
     *   public_dns: public IP of the proxy
     *   ssh_port: 22
     *   ssh_user_password: "$password"
     *   ssh_username: "vm60269"
     *   tunnelKey: ${(adu|vmu)_id}_${vm_id}, e.g: vmu123_321, adu456_789
     *   description: "VM Descriptor"
     *   vm_id: 123
     * }
     */

    // initialize tunnel status
    if (!this.tunnelsStatus[this.activeVM.tunnelKey])
      this.tunnelsStatus[this.activeVM.tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL
  }

  //@action
  async controlByContext(vm = null) {

    if (vm) {
      this.activeVM = { ...vm };

      // initialize tunnel status if not available
      if (!this.tunnelsStatus[vm.tunnelKey])
        this.tunnelsStatus[vm.tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL;
    }

    if (!this.activeVM) return;

    // copy the current activeVM
    const currentActiveVM = { ...this.activeVM };

    // lock other user actions based on status
    if (this.isRedirectionInProgress) {
      console.log("camera redirection in progress ...");
      return;
    }

    if (this.isStreaming) {// toggle redirection
      if (!!vm && this.tunnelsStatus[vm.tunnelKey] === CameraController.TUNNEL_STATUS.TUNNELING) return;

      await this.toggleTunnel(currentActiveVM);
    } else {
      // get cameras and set selected camera
      await this.scan();

      if (!this.preferredCamera) { // open CameraSelectionModal, display list of cameras
        console.log('Do not have preferred camera');
        window.$('#setup-camera').modal('show');

        await new Promise(r => setTimeout(r, 1000));// delay 1s

        // waiting until the CameraSelectionModal closed
        let isCameraSelectionModalClosed = false;
        await poll(() => {
          isCameraSelectionModalClosed = !(window.$('#setup-camera')[0].className.includes("show"));
          return isCameraSelectionModalClosed;
        }, 1000); // interval
      }

      console.log("Preferred camera:", toJS(this.preferredCamera));
      await this.redirectCameraTo(currentActiveVM)
      return;
    }
  }

  //@action
  async redirectCameraTo(vm) {

    if (!vm) {
      if (this.activeVM)
        vm = { ...this.activeVM }
      else {
        window.alert("No VM to redirect!")
        return
      }
    }

    if (!(await this.startCamera())) {
      window.alert("Failed to open the preferred webcam.\nPlease check the webcam is connected!")
      return
    }

    if (!(await this.openVideoStreamSSHtunnel(vm))) {
      window.alert(`Camera redirection to "${vm.description}" failed.\nPlease make sure you can log in to the remote computer before try again!`)
      return
    }
  }

  //@action
  async scan() {
    try {
      this.status = 'scanning';
      this.message = 'Scanning available cameras ...';

      this.availableDevices = await window.api.scanCameras();

      const foundDevice = this.preferredCamera;

      if (this.availableDevices.length > 0) {
        // reselect the previous selected device
        if (foundDevice)
          this.updateSelectedDevice(foundDevice.id);
        else { // add a null device
          this.availableDevices.unshift(this.NULL_DEVICE);
          this.updateSelectedDevice(this.NULL_DEVICE.id);
        }
      } else
        this.selectedDevice = this.NULL_DEVICE;

      console.log("scan done! selected device:", toJS(this.selectedDevice));
      this.status = 'done';
      this.message = '';
    } catch (error) {
      this.status = 'failed';
      console.error(error);
    }
  }

  //@action
  syncSelectedDeviceFromLocalStorage(camera) {
    this.selectedDevice = camera;
  }

  //@action
  updateSelectedDevice(id) {

    try {
      const foundDevice = this.availableDevices.find(d => d.id === id);

      if (!foundDevice) throw new Error("Not found camera device with id " + id);

      this.selectedDevice = foundDevice;

    } catch (error) {
      console.error(error);
    }
  }

  //@action
  async startCamera() {

    // no camera device
    if (this.selectedDevice.id === this.NULL_DEVICE.id) return false;

    this.streamStatus = CameraController.STREAM_STATUS.STARTING_STREAM;

    this.message = "Start camera redirection ..."

    if ((await window.api.startCamera(this.selectedDevice.id)) === false) { // failed to start local stream
      this.message = "Failed to start the camera redirection.\nPlease check you camera is connected!"
      this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
    }

    if ((this.streamStatus === CameraController.STREAM_STATUS.STARTING_STREAM)) {
      this.streamStatus = CameraController.STREAM_STATUS.STREAMMING;
      return true;
    } else
      return false;
  }

  //@action
  async stopCamera() {

    this.streamStatus = CameraController.STREAM_STATUS.STOPPING_STREAM;

    await window.api.stopCamera(this.selectedDevice.id);
    console.log("Stopped camera stream");

    this.streamURL = '';
    this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
    this.message = "";
  }

  //@action
  updateTunnels() {
    this.tunnels = window.api.cameraTunnels()
  }

  //@action
  async toggleTunnel({
    ssh_port,
    ssh_username,
    public_dns,
    ssh_user_password,
    private_ip,
    tunnelKey,
    description,
    vm_id,
    sid
  }) {

    if (this.tunnelsStatus[tunnelKey] === CameraController.TUNNEL_STATUS.TUNNELING)
      return await this.closeVideoStreamSSHtunnel(tunnelKey)
    else if (this.tunnelsStatus[tunnelKey] === CameraController.TUNNEL_STATUS.NO_TUNNEL)
      return await this.openVideoStreamSSHtunnel({
        ssh_port,
        ssh_username,
        public_dns,
        ssh_user_password,
        private_ip,
        tunnelKey,
        description,
        vm_id,
        sid
      });

    //else
    console.error("Tunnel action in progress ...")
    return false;
  }

  //@action
  async closeVideoStreamSSHtunnel(tunnelKey) {
    if (!tunnelKey) return

    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.STOPPING_TUNNEL

    await Promise.all([
      window.api.stopPublishing(tunnelKey, false),
      window.api.closeForwardSSHtunnel(tunnelKey + "-1"),
      window.api.closeForwardSSHtunnel(tunnelKey + "-2"),
    ])

    this.updateTunnels()
    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL
  }

  //@action
  async openVideoStreamSSHtunnel({
    ssh_port,
    ssh_username,
    public_dns,
    ssh_user_password,
    private_ip,
    tunnelKey,
    description,
    vm_id,
    sid
  }) {

    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.STARTING_TUNNEL
    this.message = "Openning secure connection ..."

    // establish SSH tunnels
    let localUMSport, localWebRTCport
    try {
      [localUMSport, localWebRTCport] = await Promise.all([
        window.api.openForwardSSHtunnel({
          "tunnelKey": tunnelKey + "-1",
          "sshUserPassword": ssh_user_password,
          "sshUsername": ssh_username,
          "publicDns": public_dns,
          "sshPort": ssh_port,
          "remoteIP": private_ip,
          "remotePort": UMS_PORT,
          "localIP": '127.0.0.1',
          "localPort": 0,
        }),
        window.api.openForwardSSHtunnel({
          "tunnelKey": tunnelKey + "-2",
          "sshUserPassword": ssh_user_password,
          "sshUsername": ssh_username,
          "publicDns": public_dns,
          "sshPort": ssh_port,
          "remoteIP": private_ip,
          "remotePort": UMS_WebRTC_PORT,
          "localIP": '127.0.0.1',
          "localPort": 0,
        })
      ])

      this.updateTunnels()

      console.log("Forward", localUMSport, `-> ${private_ip}:${UMS_PORT} UMS Players`)
      console.log("Forward", localWebRTCport, `-> ${private_ip}:${UMS_WebRTC_PORT} UMS WebRTC`)

      // todo: allow user to change settings
      window.api.startPublishing({
        localStreamId: this.selectedDevice.id,
        alias: tunnelKey,
        password: sid,
        ipAddress: "127.0.0.1",
        WebRTCProtocol: "tcp",
        videoCodec: "H264",
        videoProfile: "profile-level-id=42e0",
        videoBitrate: "800",
      })

      let isPublished = false
      this.callingAPIs[tunnelKey] = false
      let checkingAttempt = sid === "" ? 20 : 1 // no sid => set checkingAttempt to 20 to call API without wait time
      await window.api.poll(async () => {

        const publishStreams = window.api.publishStreams()
        console.log(`Checking publishing ${tunnelKey} attempt #`, checkingAttempt++, publishStreams)

        // tunnelKey is removed from publishStreams => failed publishing
        if (!(tunnelKey in publishStreams))
          return true // exit polling

        if (publishStreams[tunnelKey]["state"] === "connected") {
          isPublished = true // update result
          return true // exit polling
        }

        // calling setup redirect camera if publishing failed 2 times or not done after 20 seconds
        if ((publishStreams[tunnelKey]["rePublish"] > 1 || checkingAttempt > 20) && this.callingAPIs[tunnelKey] === false) {

          this.callingAPIs[tunnelKey] = true // ensure call ONLY 1 time per user camera redirection
          console.warn(`Calling API to setup redirect camera for ${tunnelKey}`)

          const byPassAxiosInterceptRequest = axios.create({
            baseURL: getItem('api_endpoint') || web_api_entrypoint,
            timeout: 100000,
          })

          byPassAxiosInterceptRequest.post(
            is_ad_user.get()
              ? `/api/adusers/${ad_user_id.get()}/setup_redirect_camera`
              : `/api/vmusers/${tunnelKey.substr(3).split("_")[0]}/setup_redirect_camera`,
            {
              alias: tunnelKey,
            }
          ).then(res => {
            console.warn("Response of setup_redirect_camera", res)

            if (res.status === 200)
              window.api.updatePublishStreamPassword(tunnelKey, res.data.sid)

          }).catch(err => {
            console.error("Error calling setup_redirect_camera API", err)
          })
        }

        return false // continue polling
      }, 130000, 1000) // timeout, interval

      if (isPublished) {
        this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.TUNNELING
        this.message = `Successfully redirect camera to ${description}`
      } else
        throw new Error(`Failed to redirect camera to ${tunnelKey}`)

      return true
    } catch (err) {

      console.error(`Camera redirection failed: ${err.message}`)
      this.message = `Camera redirection to ${description} failed.\nPlease make sure you can log in to the remote computer before try again.`
      await this.closeVideoStreamSSHtunnel(tunnelKey)

      return false
    } finally {
      if (this.callingAPIs.hasOwnProperty(tunnelKey)) {
        delete this.callingAPIs[tunnelKey]
        console.log(`Remove callingAPIs[${tunnelKey}]`, this.callingAPIs)
      }
    }
  }

  //@computed
  get preferredCamera() {
    return (this.selectedDevice.id === this.NULL_DEVICE.id)
      ? null
      : this.availableDevices.find(cam => cam.name === this.selectedDevice.name);
  }

  //@computed
  get isStreaming() {
    return this.streamStatus === CameraController.STREAM_STATUS.STREAMMING;
  }

  //@computed
  get isRedirectionInProgress() {
    return this.status === 'scanning'
      || this.streamStatus === CameraController.STREAM_STATUS.STARTING_STREAM
      || this.streamStatus === CameraController.STREAM_STATUS.STOPPING_STREAM
      || this.tunnelsStatus[this.activeVM.tunnelKey] === CameraController.TUNNEL_STATUS.STARTING_TUNNEL
      || this.tunnelsStatus[this.activeVM.tunnelKey] === CameraController.TUNNEL_STATUS.STOPPING_TUNNEL;
  }

  //@computed

  //@computed
  get iconStatus() {// TopBar icon
    let result = 'fa fa-video-camera';

    if (!!this.activeVM) {

      if (this.isRedirectionInProgress)
        result = 'fa fa-spinner fa-spin fa-fw';
    }

    return result;
  }

  //@computed
  get iconStyle() {// TopBar icon style
    let result = { color: "white" };

    if (!!this.activeVM) {
      const status = this.tunnelsStatus[this.activeVM.tunnelKey];
      console.log('iconStyle for status', status);

      if (status === CameraController.TUNNEL_STATUS.TUNNELING)
        result = { color: "red" };
    }
    return result;
  }

  //@computed
  get actionStatus() {
    let result = "Redirect camera"

    if (!!this.activeVM) {
      const status = this.tunnelsStatus[this.activeVM.tunnelKey]
      console.log('actionStatus', status)

      if (this.status === 'scanning')
        result = "Scanning ..."
      else if (this.streamStatus === CameraController.STREAM_STATUS.STARTING_STREAM)
        result = "Starting ..."
      else if (status === CameraController.TUNNEL_STATUS.NO_TUNNEL)
        result = "Redirect camera"
      else if (status === CameraController.TUNNEL_STATUS.TUNNELING)
        result = "Stop redirection"
      else
        result = "Redirecting ..."
    }

    return result
  }
}

export const cameraController = new CameraController();

observe(active_perm, () => {

  cameraController.updateActiveVM(active_perm.get());

  //console.log('active_perm is ', active_perm.get());

});

const checkToStopRedirection = async function (controller = cameraController) {
  // check publishStream
  const publishStreams = await window.api.publishStreams()
  Object.keys(cameraController.tunnelsStatus).forEach(async (tunnelKey) => {

    if (cameraController.tunnelsStatus[tunnelKey] === CameraController.TUNNEL_STATUS.TUNNELING) {

      if (tunnelKey in publishStreams) {

        const publishStreamState = window.api.getPublishStreamState(tunnelKey)

        if (publishStreamState && publishStreamState !== "connected") {
          console.warn(`Publish stream ${tunnelKey} is not connected (state: ${publishStreamState})`)

          // try to reconnect 
          if (!(publishStreams[tunnelKey]["scheduleNewPublish"])
            && (publishStreamState === "closed" || publishStreamState === "failed")) {

            window.api.stopPublishing(tunnelKey)
            console.warn(`Schedule new Re-publish stream ${tunnelKey}`)

          } else
            console.warn(`Re-publish stream ${tunnelKey} is in process`)
        }
      } else { // the tunnelKey is removed e.g. cannot reconnect => cleanup this camera redirection
        console.log(`Publish stream ${tunnelKey} was failed => stop camera redirection`)
        await cameraController.closeVideoStreamSSHtunnel(tunnelKey)
      }

    }
  })

  if (await window.api.isRDPrunning()) {
    //// console.log("Do not stop the stream because RDP is running")
    return
  }

  // get all active vmuser.id (tunnelKey) from vm_cache
  const activeVMuserIds = [...vm_cache.keys()]
    .filter(perm_id => permission_list.get()[perm_id])
    .map(perm_id => ad_user_id.get() ? `adu${ad_user_id.get()}_${permission_list.get()[perm_id].vm.id}`
      : `vmu${permission_list.get()[perm_id].vmuser.id}_${permission_list.get()[perm_id].vm.id}`)
  console.log("activeVMuserIds", activeVMuserIds)

  // filter tunnelsStatus that has TUNNELING status and not an active connection
  const shouldBeClosedTunnelKeys = (Object.keys(controller.tunnelsStatus))
    .filter(key =>
      controller.tunnelsStatus[key] === CameraController.TUNNEL_STATUS.TUNNELING
      && !activeVMuserIds.includes(key))
  console.log("shouldBeClosedTunnelKeys", shouldBeClosedTunnelKeys)

  shouldBeClosedTunnelKeys.forEach(tunnelKey => cameraController.closeVideoStreamSSHtunnel(tunnelKey))
}
