
const poll = async (f, interval = 100) => {

    let output;

    while(!(output = f())) {
        
        await new Promise(r => setTimeout(r, interval));

    }

    return output;

};
export default poll
