import {observable} from 'mobx';


export const active_perm = observable.box();

export const active_vm_src_override = observable.box();

// Currently initialized vm iframe element

export const active_vm_frame = observable.box();
