import React from 'react';
import { observer } from 'mobx-react';

export const CameraSelectionModal = observer(({controller}) => {
  // console.log(controller);
  const availableDevices = controller.availableDevices.filter((device) => device.id !== -1);

  return (
    <div className="modal fade" id="setup-camera" tabIndex="-1" role="dialog" aria-labelledby="setup-camera" aria-hidden="true">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title inner-modal-dashboard">
              <i className="fa fa-video-camera pr-2" style={{color: "white"}}></i> 
              {controller.title}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-3">
            <div className="the-content-inner text-dark">
            {
              availableDevices.length
              ? <ul className="list-group">
                {availableDevices.map((device) =>
                  <li className="list-group-item font-weight-bold text-secondary mb-1 border-secondary rounded" key={device.id} 
                  style={{cursor: "pointer"}}
                  value={device.id}
                  onClick={e => {
                    controller.updateSelectedDevice(device.id);
                    window.$('#setup-camera').modal('hide');
                  }}>
                    <i className="fa fa-video-camera fa-lg pr-3" style={{color: "#00438b"}}></i>
                    {device.name}
                  </li>
                )}
              </ul>
              : <div className="row">
                  <div className="col col-11">
                    { controller.status === 'scanning'
                      ? <h6 className="mt-2">{controller.message}</h6>
                      : <h6 className="mt-2 text-danger">No devices found. Please try again!</h6>
                    }
                  </div>
                  <div className="col col-1 p-0">
                  { controller.status === 'scanning'
                    ? <span className="spinner-border ml-2" role="status" aria-hidden="true" style={{color:"#00438b"}}></span>
                    : <button type="button" className="btn pl-2" onClick={controller.scan} style={{fontSize:"0.8rem"}}>
                        <i className="fa fa-refresh fa-2x" style={{color:"#00438b"}} aria-hidden="true"></i>
                      </button>
                  }
                  </div>
                </div>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
