import React from 'react';
import { observer } from 'mobx-react';
import { settingsController }  from '../../SettingsController';
import { microphoneController, soundController } from '../../rpiClient/AudioController.js';
import { printerController } from '../../rpiClient/PrinterController.js';
import { cameraController } from '../../CameraController.js';
import { AudioSettings } from './AudioSettings.js';
import { CameraSelection } from '../CameraSelection.js';

export const RPiSettings = observer(() => {

  const rpi_settings = settingsController.getSettings();

  const printerList = printerController.availableList;
  const printerSelectedList = printerController.selectedList;

  return ( <>
    <h5 className="card-title px-3 py-1" style={{ fontWeight:"bold", color:"#00438b"}}><i className="fa fa-video-camera video-cam-icon" aria-hidden="true"></i>Video & Audio </h5>
    <div className="card">
{/* Video and Audio */}
      { !window.api.isNewOS && <div className="card-body pb-0 pt-0">
        <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Speaker</h6>
            <AudioSettings controller={soundController}/>
      </div> }

      { !window.api.isNewOS && <div className="card-body pb-0 pt-0">
        <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Microphone</h6>
            <AudioSettings controller={microphoneController}/>
      </div> }

      <div className="card-body pb-0 pt-0">
        <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Webcam</h6>
            <CameraSelection controller={cameraController}/>
            <video style={{display: "none"}} className="col-12 pt-2 pl-3 pr-4" id="localVideo" autoPlay playsInline muted></video>
      </div>

      { window.api.isNewOS && 
        <div className="card-body pb-0 pt-1">
          <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Audio</h6>
          <div className="col col-md-12">
            <button type="button" className="btn btn-blue btn-block pl-2"  onClick={() => window.api.openPulseAudio()}>
              Open Audio Settings
            </button>
          </div>
        </div>
      }
    </div>

{/* Connection */}
    <h5 className="card-title px-3 py-1 mt-4 connection-title" style={{ fontWeight:"bold", color:"#00438b"}}><i className="fa fa-desktop connection-icon" aria-hidden="true"></i>Connection</h5>
    <div className="card">

      <div className="form-check form-check-inline card-body pb-3 pt-0">
        <input className="form-check-input" type="checkbox" id="useRDPcheckbox" data-toggle="collapse" data-target="#useRDPoptions"
        checked={rpi_settings.useRDP}
        value="useRDP"
        onChange={e => settingsController.toggleOptionByValue(e.target.value)}
        />
        <label className="form-check-label" htmlFor="useRDPcheckbox" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
          Always use RDP
        </label>
      </div>
  {/* RDP */}
      <div className="card-body pb-0 pt-0">

        <div className="ml-3">

          <div className="form-check form-check-inline col-12">
            <input className="form-check-input" type="checkbox" id="useMultimon"
            defaultChecked={rpi_settings.useMultimon}
            value="useMultimon"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
              <label className="form-check-label" htmlFor="useMultimon" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
                Use all monitors
              </label>
          </div>

          <div className="form-check form-check-inline col-12">
            <input className="form-check-input" type="checkbox" id="redirectSound"
            defaultChecked={rpi_settings.redirectSound}
            value="redirectSound"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
              <label className="form-check-label" htmlFor="redirectSound" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
                Play sound on this computer
              </label>
          </div>

          <div className="form-check form-check-inline col-12">
            <input className="form-check-input" type="checkbox" id="redirectMicrophone"
            defaultChecked={rpi_settings.redirectMicrophone}
            value="redirectMicrophone"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
              <label className="form-check-label" htmlFor="redirectMicrophone" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
                Record from microphone of this computer
              </label>
          </div>

          <div className="form-check form-check-inline col-12">
            <input className="form-check-input" type="checkbox" id="redirectCamera"
            defaultChecked={rpi_settings.redirectCamera}
            value="redirectCamera"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
            <label className="form-check-label noselect" htmlFor="redirectCamera" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
              Always redirect camera when using RDP
            </label>
          </div>

          <div className="form-check form-check-inline col-12">
            <input className="form-check-input" type="checkbox" id="redirectAllPrinters"
            defaultChecked={rpi_settings.redirectAllPrinters}
            value="redirectAllPrinters"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
            <label className="form-check-label noselect" htmlFor="redirectAllPrinters" style={{ WebkitUserSelect: "none", cursor: "pointer" }}>
              Redirect all available printers
            </label>
          </div>
        </div>

  {/* PRINTERS */}
        { !(rpi_settings.redirectAllPrinters) &&
        <div className="pl-3 pt-2">
        {/* <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Printers</h6> */}
          <div className="row pl-3">
            <h6 className="card-subtitle mb-2 text-muted">Select preferred printers to use on your remote desktop (Hold CTRL to select multiple printers)</h6>
            <div className="form-group col-11 p-0 mb-0">
              {printerList.length > 0
              ? <select multiple={true} size="3" id="printerDevices" className="form-control border-secondary" value={printerSelectedList}
              onChange={printerController.updateSelectedPrinters}
              style={{ WebkitUserSelect: "none", cursor: "pointer" }}
              >
                {printerList.map((printer) =>
                  <option key={printer} value={printer}>{printer}</option>
                )}
                </select>
              : printerController.status === 'scanning'
                ? <h6 className="mb-2">Finding available printers ...</h6>
                : <h6 className="mb-2 text-danger">No printer found.<br/>Please check printer connection and try again!</h6>
              }
            </div>
            <div className="col col-1 p-0">
              { printerController.status === 'done'
                ? <button type="button" className="btn" onClick={printerController.scan} style={{fontSize:"0.8rem"}}>
                    <i className="fa fa-refresh fa-2x" style={{color:"#00438b"}} aria-hidden="true"></i>
                  </button>
                : <span className="spinner-border ml-2" role="status" aria-hidden="true" style={{color:"#00438b"}}></span>
              }
            </div>
          </div>
        </div> 
        }
        { window.api.isNewOS && 
        <div className="card-body pb-0 pt-1">
          <div>
            <button type="button" className="btn btn-blue btn-block pl-2"  onClick={() => window.api.openPrintSettings()}>
              Open Advanced Print Settings
            </button>
          </div>
        </div>
        }
      </div> {/* RDP End */}
    </div> {/* Connection End */}
  </>
  );
});
