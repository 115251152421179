import React from 'react';
import {observer} from 'mobx-react';
import * as auth from '../../auth.js';
import {full_name, email} from '../../userData.js';
import {VMList, search_filter} from './VMList.js';
import {ActiveConnections} from './ActiveConnections.js';
import { soundController, microphoneController } from '../../rpiClient/AudioController';
import { cameraController } from '../../CameraController.js';
import { printerController } from '../../rpiClient/PrinterController';


export const Sidebar = observer(() =>

  <div className="the-left-section">
    <div className="the-sidebar" style={{overflow: 'visible', position: 'absolute', width: '275px'}}>
      <div className="sidebar-inner">
        <div className="top-fields" style={{padding: '15px', backgroundColor: '#003977'}}>
          <div className="btn-group the-profile-name">
            <button className="btn btn-default" type="button" style={{textAlign: 'left', whiteSpace: 'nowrap', paddingLeft: '7px', paddingRight: '7px', marginTop: '0px'}}><i className="fa fa-user-circle-o" aria-hidden="true" style={{marginRight: '7px'}}/>{email.get()}</button>
            <button data-toggle="dropdown" className="btn btn-default dropdown-toggle" type="button" style={{marginTop: '0px'}}><span className="caret" />
            </button>
            <ul className="dropdown-menu">
              <li className="account-title-v2">{full_name.get()}</li>
              <li className="signout-login">
                <a href="#."
                  onClick={(e) => {
                    e.preventDefault()
                    auth.logout();

                    // ssh tunnels
                    window.isElectron && window.api.logout_cleanup();
                }}><i className="fa fa-sign-out" style={{color: '#00438b'}} aria-hidden="true" /> Sign out</a>
              </li>
            </ul>
          </div>


          <div className="search-field">
            <input
              className="form-control" type="text" placeholder="Search" aria-label="Search"
              value={search_filter.get()}
              onChange={v => search_filter.set(v.target.value)}
              />
            <i className="fa fa-search" />
          </div>
        </div>

        <ActiveConnections/>

        <VMList/>

      </div>



      { window.isElectron && // enable for all OSes
        <div className="the-lower-content" style={{zIndex: 1, overflow: "hidden"}}>
          <ul>
            <li className="d-flex flex-row" style={{backgroundColor: '#00438b'}}>
              <button type="button" className="btn btn-default btn-block text-left p-1" data-toggle="modal" data-target="#settings-modal" style={{color: '#fff'}}
                onClick={ () => {
                    if(window.isRPi) {
                      soundController.scan();
                      microphoneController.scan();
                      !printerController.status !== 'scanning' && printerController.scan();
                    }
                    !cameraController.isStreaming && cameraController.scan();
                  }
                }
              >
                <i className="fa fa-cog py-2 pr-2" aria-hidden="true"></i>
                Settings
              </button>

              { cameraController.isStreaming &&
                <button type="button" className="btn p-1" onClick={() => cameraController.stopCamera()}
                data-toggle="tooltip" data-placement="top" title="Stop the camera"
              >
                  <i className="fa fa-video-camera p-2" aria-hidden="true" style={{color: 'red'}}></i>
              </button>
              }
            </li>
          </ul>
        </div>
      }

    </div>
  </div>

)
