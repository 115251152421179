import React from 'react';
import axios from 'axios';
import {is_ad_user, permission_list} from '../../userData.js';
import {LoadingScreen} from '../LoadingScreen.js';
import {active_perm} from '../../activeVM.js';
import {vm_cache} from '../util/WebconnectFrame.js';
import {poll_task} from '../../connectUtils.js';


// Simple higher-order component that replaces the src property with the result
// of the async get_link() function defined below.

const component = Component => (

  class extends React.Component {

    constructor(props) {

      super(props);

      this.state = {src: ''};


      // React says you need to cancel this if the component gets unmounted.
      // I will just leave as-is because I think that's silly.

      (async () => this.setState({src: await get_link(props.perm_id)}))();

    }

    render() {

      const {src, ...rest} = this.props;

      return (
        this.state.src ?
          <Component src={this.state.src} {...rest}/> :
          <LoadingScreen/>
      );
    }

  }

);
export default component


// Does an api call to derive the desired iframe src url from the vm_user_string

const get_link = async perm_id => {

  let url, task_id;

  try {

    if(is_ad_user.get() && perm_id.startsWith('pool')) {

      // https://app.v2cloud.com/#/pool_70
      // https://app.v2cloud.com/#/pool_70_82

      // eslint-disable-next-line
      const [_, pool_id, access_id] = perm_id.split('_')

      let q = `/api/advmpoolaccesss/${pool_id}/webconnect_direct_url?format=json`;

      // eslint-disable-next-line
      const data = (await axios({
        url: q,
        params: {
          ...(access_id && { access: access_id }),
          format: 'json'
        }
      })).data;

    } else if(is_ad_user.get() && !perm_id.startsWith('pool')) {

      let pl = permission_list.get();
      let perm = pl[perm_id];

      let q = ''

      if(perm.type === 'vmpool' || perm.type === 'vmpool-app') {
        q = `/api/advmpoolaccesss/${perm.id}/webconnect_direct_url`;
      } else {
        q = `/api/advmaccesss/${perm.id}/webconnect_direct_url`;
      }

      const data = (await axios({
        url: q,
        params: {
          ...(perm.app && { app: perm.app.id }),
          format: 'json'
        }
      })).data;

      // these checks can be removed onced format=json is supported in stable
      if(typeof data === 'string') {
        url = data;
      } else {
        url = data.url;
        task_id = data.task && data.task.id;
        pl[perm_id].last_vmpool_vm_id = data.vm;
        pl[perm_id].last_vmpool_vmuser_id = data.vmuser;
        permission_list.set(pl);
      }

    } else {

      const data = (await axios({
        url: `/api/permissions/${perm_id}/webconnect_direct_url`,
        params: {
          format: 'json'
        }
      })).data;

      if(typeof data === 'string') {
        url = data;
      } else {

        url = data.url;
        task_id = data.task && data.task.id;

        const pl = permission_list.get();

        if(pl[perm_id]) {
          pl[perm_id].last_vmpool_vm_id = data.vm;
          pl[perm_id].last_vmpool_vmuser_id = data.vmuser;
          permission_list.set(pl);
        }

        if(task_id) {
          await poll_task(task_id);
        }
      }
    }

  } catch(e) {

    active_perm.set();
    vm_cache.delete(perm_id);

    throw e;

  }


  if(window.location.hostname === '127.0.0.1') {

    // go through our proxy so that we have same-origin conditions


    const without_protocol = url.substring('https://'.length);

    const without_path = without_protocol.split('/')[0];

    const subdomain = without_path.split('.').slice(0, -2).join('.')


    let without_orginSlash = without_protocol.split('/');
    without_orginSlash.shift();
    without_orginSlash = without_orginSlash.join('/');


    // see electron/server.js

    url = window.location.origin + '/subdomain/' + subdomain + '/' + without_orginSlash;

  }


  // add v2dekstopclient to the search params to disable the bar
  url += '&v2mobileclient';

  return url;

};
