import React from 'react';
import {user_list, fetch_vmusers} from '../../userData.js';
import {VMUser} from './VMUser.js';
import {VMStatus} from '../VMStatus.js';

const collapsed_cache = {};


export const VMRoot = class extends React.Component {

  constructor(props) {

    super(props);


    // Remember collapse state if this is after a refresh

    if(collapsed_cache[this.props.api.id] === false) {
      setTimeout(() => this.toggleCollapse(), 0);
    }

    this.state = {
      collapsed: true,
      loading: false,
    };

  }

  async toggleCollapse() {

    if(this.state.collapsed) {

      this.setState({
        loading: true
      });


      try {

        await fetch_vmusers(this.props.api.id);


      } catch(e) {
        console.error(e);

        this.setState({
          loading: false,
          collapsed: true
        });

        return;
      }

      this.setState({
        loading: false,
        collapsed: false
      });


    } else {

      this.setState({
        collapsed: true
      });

    }

  }

  render() {

    const {descriptor, id, state} = this.props.api;
    collapsed_cache[id] = this.state.collapsed;

    return (

      <li className="the-menu-parent" key={id}>

         <span>
           <div className="left-icons">
              <i className="fa fa-spinner fa-pulse" style={{display: this.state.loading ? 'inline-block' : 'none'}}></i>
              <i onClick={() => this.toggleCollapse()} className={"fa fa-caret-" + (this.state.collapsed ? "right" : "down")}
                 style={{display: this.state.loading ? 'none' : 'inline'}}></i>
           </div>
           <a href="#." 
              onClick={(e) => {
                e.preventDefault()
                this.toggleCollapse()
            }}>
            <VMStatus state={state} id={id} style={{marginRight: '8px', marginLeft: '-5px', fontSize: '12px',}}/>
            {descriptor}
           </a>
         </span>

         { /* <div className="status-bar" id="status-bar">
            <i className="fa fa-refresh refresh-button status-icon-bg-hover-only" aria-hidden="true" title="Refresh"></i><i className="fa-custom fa fa-times signout-button status-icon-bg-hover-only" aria-hidden="true" title="Sign off"></i>
         </div> */ }

         { !this.state.collapsed &&

         <div className="the-items">
           <ul className="list-items user-pc">

            { user_list.get()[id].map(({os_username: username, login_method, user, id: vmuser_id}) => (

              <VMUser key={user.id} vm_id={id} email={user && user.email ? user.email : ""} user_id={user.id} vmuser_id={vmuser_id} username={username} login_method={login_method}/>

            ))}

           </ul>
         </div>

        }

      </li>


    );

  }

};
