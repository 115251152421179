
// This is the share screen controller from the Angular app, slightly modified
// to be suitable for injection. We are including it here so that we can do the 
// same operations without appending a modal to the screen.

// dialog/controllers/v2cShareScreenController.js

// https://github.com/v2cloud/guacamole-webconnect-ext/blob/a699d684bef57f67130a38723046484db0195c73/src/main/resources/dialog/controllers/v2cShareScreenController.js



// It is also possible to revise this controller from the Angular app into a 
// service so that it is always persistent. While 
// that would reduce duplication, one-time-initialization may lead to unexpected 
// behavior.


const v2cShareScreenController = iframe => {


    const injector = iframe.contentWindow.angular.element(iframe.contentDocument).injector();
    const get_service = service_name => injector.get(service_name);

    const $scope = iframe.contentWindow.angular.element(iframe.contentDocument).scope();


    const guacClientManager       = get_service('guacClientManager');
    const tunnelService           = get_service('tunnelService');
    const requestService          = get_service('requestService');
    const ManagedClient           = get_service('ManagedClient');
    const $routeParams            = get_service('$routeParams');
    const sharingProfileService   = get_service('sharingProfileService');
    const authenticationService   = get_service('authenticationService');

        
    $scope.client = guacClientManager.getManagedClient($routeParams.id, $routeParams.params);
    $scope.sharingProfiles = {};
    $scope.selectedDataSource = authenticationService.getDataSource();


    // This hooks up the controller with the sharing profile services established in the base guacamole-client app.

    // The base guacamole-client app then interacts with an http api to modify sharing profiles.


    $scope.share = function share(sharingProfile) {
        ManagedClient.createShareLink($scope.client, sharingProfile);
    };

    $scope.retrieveSharingProfiles = function retrieveSharingProfiles(uuid) {
        // Only pull sharing profiles if tunnel UUID is actually available
        if (!uuid)
            return;
        // Pull sharing profiles for the current connection
        tunnelService.getSharingProfiles(uuid)
            .then(function sharingProfilesRetrieved(sharingProfiles) {
                $scope.sharingProfiles = sharingProfiles;
                for (let identifier in $scope.sharingProfiles){
                    if ($scope.sharingProfiles.hasOwnProperty(identifier)) {
                        $scope.share($scope.sharingProfiles[identifier]);
                        sharingProfileService.getSharingProfileParameters($scope.selectedDataSource, identifier)
                            .then(function sharingProfileParametersRetrieved(parameters) {
                                $scope.sharingProfiles[identifier].parameters = parameters;
                            });
                    }
                }
            }, requestService.WARN);

    };


    $scope.retrieveSharingProfiles($scope.client.tunnel.uuid);

    $scope.$watch('client.tunnel.uuid', $scope.retrieveSharingProfiles);


    // This controls whether the form displays that the link is available

    $scope.canShareConnection = function canShareConnection() {

        // If there is at least one sharing profile, the connection can be shared
        for (let dummy in $scope.sharingProfiles)
            return true;

        // Otherwise, sharing is not possible
        return false;

    };

    $scope.isShared = function isShared() {
        return ManagedClient.isShared($scope.client);
    };



    // This controls the toggle buttons in the form

    $scope.isReadOnly = true;
    $scope.readOnlyChanged = function readOnlyChanged() {
        for (let identifier in $scope.sharingProfiles) {
            if ($scope.sharingProfiles.hasOwnProperty(identifier)) {
                let sharingProfile = $scope.sharingProfiles[identifier];
                if ($scope.isReadOnly)
                    sharingProfile.parameters['read-only'] = 'true';
                else if (sharingProfile.parameters && 'read-only' in sharingProfile.parameters)
                    delete sharingProfile.parameters['read-only'];

                sharingProfileService.saveSharingProfile($scope.selectedDataSource, sharingProfile)
                    .then(function saveSharingProfile() {
                        $scope.share(sharingProfile);
                        $scope.sharingProfiles[identifier] = sharingProfile;
                    });
            }
        }
    };

    $scope.readOnlyChanged();


    return $scope;


};
export default v2cShareScreenController
