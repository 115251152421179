import React from 'react';
import {permission_list, vms_list} from '../../userData.js';
import {open_vm} from '../../useRDP.js';


export const VMPeekaboo = class extends React.Component {

  componentDidMount() {

    // Remove this popup when the user clicks somewhere

    // Eliminate it from the event loop such that clicks can register before the element becomes hidden

    this.destroy = e => setTimeout(() => {
      window._destroy_peekaboo = undefined;
      this.props.destroy();
    }, 0);

    window.addEventListener('mouseup', this.destroy);


    // Remove this popup if another popup gets mounted without a click
    // (possible with async API behavior)

    window._destroy_peekaboo && window._destroy_peekaboo();
    window._destroy_peekaboo = this.destroy;

  }

  componentWillUnmount() {

    window.removeEventListener('mouseup', this.destroy);
    this.destroy();

  }

  click(perm_id) {

    open_vm(perm_id);

  }

  render() {

    const {vmuser_id} = this.props;

    const is_selected = false;
    const is_highlighted = false;

    return (

      <div style={{
        top: 0,
        background: '#003977',
        position: 'absolute',
        left: '275px',
        width: '100%',
        maxWidth: '250px'
      }}
      className='peekaboo'>

        {/* a list of the permissions using the same CSS as the main list */}

        <ul className="list-items user-pc">

          { Object.values(permission_list.get()).filter(({vmuser}) => vmuser && (vmuser.id === vmuser_id)).map(({id: perm_id, app, vm}) =>

            <li
              key={perm_id}
              style={{
                marginLeft: 0,
                paddingLeft: 0
              }}>
              <a href="#."
                className={is_selected ? "active" : (is_highlighted ? "available" : '')}
                onClick={(e) => {
                  e.preventDefault()
                  this.click(perm_id)
                }}
                >

                {app === null ? vms_list.get()[vm.id].descriptor : app.display_name}
              </a>
            </li>

          )}

        </ul>

      </div>

    );

  }

};
