import { action, computed, autorun, observable, makeObservable } from 'mobx'

class WifiController {

  /* wifi object example
  {
    ssid: "nvdhau",
    isConnected: false,
    securityKey: "password"
  }
  */
  firstLoad = true;// autoload 1st time

  //@observable
  wifis = [];
  status = 'loading';
  message = '';

  constructor() {
    makeObservable(this, {
      wifis: observable,
      status: observable,
      message: observable,
      setWifis: action,
      connectWifi: action.bound,
      updateSecurityKey: action.bound,
      scanWifis: action.bound,
      connectedWifi: computed,
      iconStatus: computed
    });
  }

  getSecurityKey(ssid) {
    const wifi = this.wifis.find(w => w.ssid === ssid);
    if (wifi) {
      return wifi.securityKey;
    }
  }

  //@action.bound
  updateSecurityKey(ssid, securityKey) {

    const wifi = this.wifis.find(w => w.ssid === ssid);
    if (wifi) 
      wifi.securityKey = securityKey
    
    console.log(JSON.stringify(wifi));
  }

  //@action 
  setWifis(wifis) {
    this.wifis = wifis;
  }

  //@action.bound
  async scanWifis() {
    try {
      this.status = 'loading';
      this.message = 'Scanning available WiFi ...'
      
      const resultWifis = await window.api.getWifiList();
      
      // move connected wifi to top
      const index = await resultWifis.findIndex(wifi => wifi.isConnected);
      
      if(index > 0){
        let temp = resultWifis[index];
        resultWifis[index] = resultWifis[0];
        resultWifis[0] = temp;
      }

      // create id without special characters 
      let i = 0;
      this.wifis = resultWifis.map(wifi => ({...wifi, id: `wifi-${[i++]}`}));
      this.status = 'success';
      
    } catch (error) {
      this.status = 'failed';
      console.log(error);
    }
  }

  //@action
  async connectWifi(ssid) {
    try {
      this.status = 'loading';
      this.message = `Connecting to "${ssid}" ...`
      const wifi = this.wifis.find(w => w.ssid === ssid);
      
      if(wifi && (!wifi.securityKey || wifi.securityKey.length < 8)) throw Error('No security key, security key too short');
      
      // disconnect the current connected wifi
      const connectedWifi = this.wifis.find(w => w.isConnected === true);
      if(connectedWifi) connectedWifi.isConnected = false;

      //call api to connect
      if (wifi && await window.api.wifiConnect({ssid: wifi.ssid,
        // remove newline in securityKey 
        psk: wifi.securityKey.replace(/(\r\n|\n|\r)/gm, "")})) {
          
        // move connected wifi to top
        this.wifis = this.wifis.filter(w => w.ssid !== ssid);
        wifi.isConnected = true;
        this.wifis.unshift(wifi);
        
        this.status = 'success';
        
      }else
        this.status = 'failed';
        

    } catch (error) {
      this.status = 'failed';
      console.log(error);
    }
  }
  
  //@computed
  get connectedWifi() {
    return this.wifis.find(wifi => wifi.isConnected);
  }
  
  //@computed
  get iconStatus() {// TopBar icon    
    if (this.wifis.length)
      return !!this.connectedWifi ? 'images/wifi-connected.png' : 'images/wifi.png';
    else 
      return 'images/wifi-splash.png';
  }
}

export const wifiController = new WifiController();
