import protocol_check from 'custom-protocol-detection';
import {desktop_link, browser_link} from './customProtocol.js';


export default window.isElectron ?

  async () => {

    window.api.open_link_external(await browser_link());

  } :

  async () => {

    window.$('#open-in-desktop').modal('show')
    await open_desktop_app();

  };




const open_desktop_app = async () => {


    protocol_check(

        await desktop_link(),


        // fail callback
        () => {},


        // success callback (application will just open)
        () => {},


        // unknown callback
        () => {}

    );

};
