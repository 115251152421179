import React from 'react';
import {fetch_user_permissions} from '../../userData.js';
import {VMPeekaboo} from './VMPeekaboo.js';
import { open_vm } from '../../useRDP.js';
import {signoff_vmuser} from '../../signoff.js';


export const VMUser = class extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      collapsed: true,
      loading: false,
    };

  }


  async _signoff() {

    if(!window.confirm('Are you sure you want to sign off this VM user?')) return;

    this.setState({loading: true});

    try {
      await signoff_vmuser(this.props.vmuser_id);
    } catch(e) {
      console.error(e);
    }

    this.setState({loading: false});

  }



  async click() {

    if(this.state.collapsed) {

      this.setState({loading: true})

      const permissions = await fetch_user_permissions(this.props.vmuser_id, this.props.vm_id);


      // if only one permission, then open it directly

      if(permissions.length === 1) {

        this.setState({loading: false});

        open_vm(permissions[0].id);

      // open the peekaboo menu

      } else {

        this.setState({
          collapsed: false,
          loading: false,
        });

      }

    } else {

      this.setState({
        collapsed: true,
        loading: false,
      });

    }

  }


  render() {
    const {vmuser_id, username, login_method, email} = this.props;

    const display_username = login_method === "self_managed" ? email : username;
    const is_selected = false;
    const in_cache = false;

    return (

      <li className="vmuser-list-item">

        <div className="status-bar" id="status-bar">
            <i onClick={() => this._signoff()} className="fa fa-sign-out refresh-button status-icon-bg-hover-only" aria-hidden="true" title="Sign off"></i>
        </div>

        <div className='list-item-container'>
          <i className="fa fa-spinner fa-pulse" style={{marginLeft: '-7px', marginRight: '7px', visibility: this.state.loading ? 'visible' : 'hidden'}}></i>
          <a href="#."
            className={is_selected ? "active" : (in_cache ? "available" : '')}
            style={{paddingLeft: '10px'}}
            onClick={(e) => {
              e.preventDefault()
              this.click()
            }}
            >
            <div className="list-item-details-container">
              <span className="list-item-username" title={display_username}>{display_username}</span>
              { login_method === "self_managed" && <span className="list-item-label">Self Managed</span> }
            </div>
          </a>
        </div>

        { !this.state.collapsed &&

          <VMPeekaboo
            vmuser_id={vmuser_id}
            destroy={() => this.setState({collapsed: true})}/>

        }

      </li>

    );

  }

};
