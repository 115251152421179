import share_screen_scope from '../webconnect/v2cShareScreenController.js';
import axios from 'axios';
import poll from '../util/poll.js';


const injector = iframe => poll(

    () => iframe.contentWindow.angular &&
          iframe.contentWindow.angular.element(iframe.contentDocument).injector()

);

const shareScreen = async iframe => {


    iframe.share_link = async isWithControl => {

        await injector(iframe);


        const url = iframe.contentWindow.location.href;

        if(url.split('#')[1].includes('uuid=')) {

          return await guacamole_json_auth_share(iframe, isWithControl);

        } else {

          return await guacamole_sql_auth_share(iframe, isWithControl);

        }

    };

};
export default shareScreen



const guacamole_sql_auth_share = async (iframe, isReadOnly) => {

  const scope = share_screen_scope(iframe);

  const current_share_link = () => Object.values(scope.client.shareLinks)[0];



  // Wait for link to be initialized

  while(current_share_link() === undefined) {

      await new Promise(r => setTimeout(r, 50));

  }


  const old_link = current_share_link().href;

  scope.isReadOnly = isReadOnly;
  scope.readOnlyChanged();


  // Wait for link to change

  while(current_share_link().href === old_link) {

      await new Promise(r => setTimeout(r, 50));

  }


  let link = current_share_link().href;

  return transform_link(link);

};


const guacamole_json_auth_share = async (iframe, isWithControl) => {

  // taken from v2cShareScreenController.js in guacamole-webconnect-ext
  // copied here because I can't find a way to invoke the actual Angular controller

  const params = new URLSearchParams(iframe.contentDocument.location.href);
  const uuid = params.get('uuid');
  const perm_id = params.get('perm');

  const onClick = async () => {

    const ro = isWithControl ? '0' : '1';

    // Including credentials allows us to verify that the currently-logged-in tokyo user has access to this permission.
    // Although the UUID is the main form of authentication.

    const response = await axios({
      url: '/api/share_url/' + uuid + '/' + perm_id + '/' + ro
    });

    const url = response.data;

    console.log(url);
    await window.navigator.clipboard.writeText(url);

    return url;

  };

  return await onClick();

};



const transform_link = link => {

  if(window.isElectron) {


    // example: http://localhost:58210/subdomain/proxy-ojw4j5qo/webconnect/#/?key=gmJ_pXQU-9XID7LZsl13WjRcPWcjMWz3_fr4jdJtTZHn

    // Generated as a relative URL from within guacamole js code, so it needs to be manually reformed.


    const subdomain = link.split('/')[4];

    if(!subdomain) {
      return undefined;
    }

    link = 'https://' + subdomain + '.v2cloud.com/webconnect/#' + link.split('#')[1];

  }

  return link;

};
