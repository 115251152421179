// Use caution. This file is modified by before-build.sh.
import {getItem} from './storage.js';

function getCanonicalHost(hostname) {
  const MAX_TLD_LENGTH = 3;

  function isNotTLD(_) { return _.length > MAX_TLD_LENGTH; };

  hostname = hostname.split('.');
  hostname = hostname.slice(Math.max(0, hostname.findLastIndex(isNotTLD)));
  hostname = hostname.join('.');

  return hostname;
}

function get_stage(hostname) {
  const regex = /-(.+?)(?:\..*\..*$)/;
  const match = hostname.match(regex);

  if (match) {
    const extractedString = match[1];
    return '-' + extractedString;
  } else {
    return "";
  }
}

const WHITELABEL_DOMAINS = ["cloudworkstations.com", "go2cloud.app"]


export const web_entrypoint = '';

let __web_api_entrypoint;
let __is_whitelabel;

if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  // On the Electron version
  __web_api_entrypoint = '';
  __is_whitelabel = false || getItem('is_whitelabel_override');
}
else {
  // On the browser version
  const canonical_domain = getCanonicalHost(window.location.hostname)
const web_api_entrypoint_template = 'https://dashDYNAMIC_STAGE_FROM_URL_TAG.DYNAMIC_DOMAIN_FROM_URL_TAG:443';
  const current_stage = get_stage(window.location.hostname)
  __web_api_entrypoint = web_api_entrypoint_template
      .replace('DYNAMIC_DOMAIN_FROM_URL_TAG', canonical_domain)
      .replace('DYNAMIC_STAGE_FROM_URL_TAG', current_stage);
  
  if (window.location.hostname.startsWith('new')) {
    __web_api_entrypoint = __web_api_entrypoint.replace(/(http:\/\/|https:\/\/)/, "$&new");
  }
  __is_whitelabel = WHITELABEL_DOMAINS.indexOf(canonical_domain) >= 0 || window.location.search.indexOf('wl') >= 0
}

export const is_whitelabel = __is_whitelabel;
export const web_api_entrypoint = __web_api_entrypoint;
export const oauth_client_id = 'uhpcke465L8xl6x3fZyduzFigM5ToeJjYNqz2opx';



