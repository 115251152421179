import axios from 'axios';
import {observable} from 'mobx';
import pkceChallenge from 'pkce-challenge';
import {web_api_entrypoint, oauth_client_id} from './consts.js';
import {show_loading_screen} from './loading.js';
import {hash} from './url.js';
import {getItem, setItem} from './storage.js';
import {is_whitelabel} from './consts.js';
import {whitelabel_info} from './whitelabel.js';

const config = {};

config.api_endpoint = web_api_entrypoint;


if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {

  config.api_endpoint = '/';

}

if(!getItem('api_endpoint')) {
  setItem('api_endpoint', '');
}

window.getItem = getItem;
window.setItem = setItem;

window.axios = axios;

axios.defaults.headers.common['Authorization'] = getItem('access_token') || '';
axios.defaults.baseURL = getItem('api_endpoint') || config.api_endpoint
axios.defaults.timeout = 60000;

// Clear access token. It gets reset on close if the user selects the persistent option.
// If the application force-closes, the token will be lost.
window.isElectron && setItem('access_token', '');


const persist_access_token = () => {

  setItem('access_token', axios.defaults.headers.common['Authorization']);

};

window.persist_access_token = persist_access_token;


export const oauth_web_api_entrypoint = getItem('api_endpoint') || (web_api_entrypoint || 'https://dash-alpha.v2cloud.com');
export const oauth_client_id_ = getItem('oauth_client_id') || (oauth_client_id || 'aQOCShKtMVsKTGs0ZSFgXFOiAuS9ezM9hSVCdMZh');

export const tokyo_api_endpoint = config.api_endpoint;


export const logged_in = observable.box();
export const auth_error = observable.box();

window.logged_in = logged_in;



const get_relative_oauth_url = () => {

	const {code_verifier, code_challenge} = pkceChallenge();
	window.localStorage.setItem('code_verifier', code_verifier);

	const oauth_state_csrf_token = Math.random().toString().slice(2);
	window.localStorage.setItem('oauth_state_csrf_token', oauth_state_csrf_token);

  let next = ('/o/authorize/?response_type=code&client_id=' + oauth_client_id_ + '&state=' + oauth_state_csrf_token + '&code_challenge=' +
              code_challenge + '&code_challenge_method=S256&redirect_uri=')
  
	if(window.isElectron) {
		const port = window.api.get_localserver_port();
    next +=  'http://127.0.0.1:' + port + "?app_open=true" + window.location.hash;
	} else {
		const redirect_uri = window.location.origin;
		next +=  redirect_uri + window.location.hash;
	}

  return '/login/?next=' + encodeURIComponent(next);
};


export const get_oauth_url = () => {

  const custom_entrypoint = whitelabel_info.get() && whitelabel_info.get().endpoint;

  // oauth_web_api_entrypoint will be one of:
  // dash(-alpha|-beta|-cloud|).v2cloud.com or dash.cloudworkstations.com
  return ((is_whitelabel && custom_entrypoint) ? custom_entrypoint : oauth_web_api_entrypoint)  + get_relative_oauth_url();

}


export const extract_url_token = show_loading_screen(async () => {

  let url = new URL(window.location.href);

  if(!url.searchParams.has('code')) return;

  const code = url.searchParams.get('code');
  const state = url.searchParams.get('state');

  window.history.replaceState({} , undefined, window.location.origin + window.location.hash);

  if(state !== window.localStorage.getItem('oauth_state_csrf_token')) {
    console.log('oauth2 CSRF verification failed. [expected state]/[reality state]: ' + window.localStorage.getItem('oauth_state_csrf_token') + '/' + state);

    return;
  }


  // redirect_uri needs to match that of the original request to the oauth authorization page
  const redirect_uri = !window.isElectron ? (is_whitelabel? window.location.origin + '?wl/' : window.location.origin) : (window.location.origin + '?app_open=true')

  const response = await axios({
    url: oauth_web_api_entrypoint + '/o/token/',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      grant_type: 'authorization_code',
      client_id: oauth_client_id_,
      code_verifier: window.localStorage.getItem('code_verifier'),
      redirect_uri,
      code,
    }
  });

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

  // keep session token in browser
  !window.isElectron && persist_access_token();

});



const logout_action = async redirect_to_oauth_login => {

  try {

    await axios({
      url: oauth_web_api_entrypoint + '/o/revoke_token/',
      method: 'post',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: {
        client_id: oauth_client_id_,
        token: getItem('access_token')
      }
    });

  } catch(e) {
    console.error(e);
  }

  setItem('access_token', '');
  setItem('refresh_token', '');

  axios.defaults.headers.common['Authorization'] = '';



  const custom_entrypoint = whitelabel_info.get() && whitelabel_info.get().endpoint;

  let logout_link = ((is_whitelabel && custom_entrypoint) ? custom_entrypoint : oauth_web_api_entrypoint) + '/logout/'

  if(redirect_to_oauth_login)
    logout_link += '?next=' + encodeURIComponent(get_relative_oauth_url());

  if(window.isElectron) {
    window.api.open_link_external(logout_link);
  } else {
    window.location.href = logout_link;
  }

};

// Used to inject logout on close; see main.js
window.logout_action = logout_action;

window.setWhitelabel = (value) => setItem('is_whitelabel_override', value);


export const logout = show_loading_screen(async () => {

  await logout_action(true);

  await test_login();

});


export const logout_no_api = () => {

  logged_in.set(false);
	hash.set('#/login');

};



axios.interceptors.response.use(function(response) {

  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;

}, function (error) {

  if(!error.response || error.response.status === 403) {

    logout_no_api();

  }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);

});


window.addEventListener('online', () => {

  auth_error.set('');
  test_login();

});

window.addEventListener('offline', () => auth_error.set('No internet connection.'));


export const test_login = show_loading_screen(async () => {

  if(!window.navigator.onLine) {

    auth_error.set('No internet connection.');

    logout_no_api();

    return;

  }


  let response;

  try {

    response = await axios({
      url: '/api/vms/'
    });

  } catch(e) {

  }


  if(!response || !response.data || !response.data.results) {

    return false;

  } else {

    // on success

    auth_error.set('');
    logged_in.set(true);

    return true;

  }

});
