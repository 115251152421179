import React from "react";
import { vms_list_original } from "../userData.js";
import { observer } from "mobx-react";


export const VMStatus = observer(({state, id, style}) => (

  <span style={{
    color: ({
      'stop': '#F44336',
      'stopping': '#F44336',
      'destroying': '#F44336',
      'error': '#F44336',
      'powered off': '#F44336',
      'running': '#8BC34A',
      'rebooting': '#FCAC2F',
      'modifying': '#FCAC2F',
      'starting': '#FCAC2F',
      'building': "#FCAC2F",
      'pending': '#FCAC2F'
    })[state || (
      vms_list_original.get().find(({id: id_}) => String(id_) === String(id)) 
      && vms_list_original.get().find(({id: id_}) => String(id_) === String(id)).state
      )] || 'black',
      ...style}}>
    &#9673;
  </span>

))
