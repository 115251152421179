import React from 'react';
import axios from 'axios';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { is_ad_user, ad_user_id, permission_list } from '../userData.js';
import { microphoneController } from '../rpiClient/AudioController.js';
import { printerController } from '../rpiClient/PrinterController.js';
import { cameraController } from '../CameraController';
import { settingsController } from '../SettingsController';
import { poll_task } from '../connectUtils.js';


const connection_steps = observable.box([], { deep: false });
let previouslRPiCameraTunnelKey = null // allow only 1 freerdp process on RPi

const add_connection_step = text => {

  connection_steps.set([
    ...connection_steps.get(),
    [text, 'loading']
  ]);

};

const change_connection_step = status => {

  connection_steps.set([
    ...connection_steps.get().slice(0, -1),
    [connection_steps.get().slice(-1)[0][0], status]
  ]);

};


export const RDPModal = observer(() => (

  <div className="modal fade" id="use-rdp" tabIndex="-1" role="dialog" aria-labelledby="use-rdp" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title inner-modal-dashboard">
            <i className="fa fa-television" aria-hidden="true" style={{ paddingRight: '5px' }}></i>Use RDP
          </h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="the-content-inner text-dark">
            <div className="row ">
              <div className="col-md-12">

                {window.isElectron ?

                  <ul style={{ listStyle: 'none' }}>

                    {connection_steps.get().map(([text, status]) =>

                      <li style={{ padding: '10px' }} key={text + status}>
                        {
                          status === 'pass' &&
                          <i className="fa fa-check-circle" style={{ color: 'green', marginRight: '10px', fontSize: '1.25em', verticalAlign: 'middle' }}></i>
                        }

                        {
                          status === 'fail' &&
                          <i className="fa fa-exclamation-triangle" style={{ color: 'red', marginRight: '10px', fontSize: '1.25em', verticalAlign: 'middle' }}></i>
                        }

                        {
                          status === 'loading' &&
                          <i className="fa fa-spinner fa-pulse" style={{ color: '#AAAAAA', marginRight: '15px' }}></i>
                        }

                        {text}
                      </li>

                    )}

                  </ul>

                  :
                  <p>
                    RDP is only available in the desktop version.&nbsp;&nbsp;
                    { /* <button style={{
                              background: '#00438b',
                              color: 'white',
                              border: 'none',
                              borderRadius: '3px',
                              padding: '7px'
                            }}
                              onClick={() => {}}><i
                              className="fa fa-desktop"
                              aria-hidden="true" style={{verticalAlign: 'middle'}}></i> Open in Desktop</button> */ }
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

));



export const open_rdp = permId => {

  if (window.$('#use-rdp').is(':visible')) {
    console.log("Aborting open_rdp(). Modal still visible.")

    return
  }

  window.$('#use-rdp').modal('show')
  
  window.isElectron && _open_rdp(permId)

}

const _open_rdp = async permId => {

  connection_steps.set([]);

  add_connection_step('Fetching V2 connection configuration');


  let rdp_params;
  let task_id;

  const pl = permission_list.get();
  const perm = pl[permId];

  try {

    if (is_ad_user.get()) {

      let q;

      if (perm.type === 'vmpool' || perm.type === 'vmpool-app') {
        q = `/api/advmpoolaccesss/${perm.id}/ssh_rdp_params`;
      } else {
        q = `/api/advmaccesss/${perm.id}/ssh_rdp_params`;
      }

      const data = (await axios({
        url: q,
        params: {
          ...(pl[permId].app && { app: pl[permId].app.id })
        }
      })).data;

      rdp_params = data;
      task_id = data.task && data.task.id;

      perm.last_vmpool_vm_id = data.vm;
      perm.last_vmpool_vmuser_id = data.vmuser;
      permission_list.set(pl);

    } else {

      const data = (await axios({
        url: `/api/permissions/${permId}/ssh_rdp_params`,
        params: {
        }
      })).data;

      rdp_params = data;
      task_id = data.task && data.task.id;

      perm.last_vmpool_vm_id = data.vm;
      perm.last_vmpool_vmuser_id = data.vmuser;
      permission_list.set(pl);

    }

    if (!rdp_params.vm)
      throw new Error("Invalid VM ID", rdp_params.vm)

  } catch (e) {

    change_connection_step('fail');
    throw e;

  }

  change_connection_step('pass');


  if (task_id) {

    add_connection_step('Creating VM pool system user');

    try {

      await poll_task(task_id);
      change_connection_step('pass');

    } catch (e) {

      change_connection_step('fail');
      throw e;

    }

  }

  let freeRDPoptions
  if (window.isRPi) {

    // fake check speaker to let user know they selected sound redirection
    if(settingsController.getSettings().redirectSound){
      add_connection_step('Checking speaker before redirection');
      change_connection_step('pass');
    }

    // check microphone
    let isMicrophoneReady;
    if (settingsController.getSettings().redirectMicrophone) {
      add_connection_step('Checking microphone before redirection');
      // skip check microphone in new RPi OS
      window.api.isNewOS || await microphoneController.scan();// rescan and try to select available microphone
      isMicrophoneReady = window.api.isNewOS || !!microphoneController.selectedDevice;
      isMicrophoneReady ? change_connection_step('pass') : change_connection_step('fail');
    }

    // rescan available printer to eliminate unavailable printers in saved printers
    (settingsController.getSettings().printers.length > 0) && await printerController.scan();

    // build options
    freeRDPoptions = {
      screen: settingsController.getSettings().useMultimon
                ? '/multimon:force'
                : '/f',
      sound: settingsController.getSettings().redirectSound
                ? `/sound:${window.api.isNewOS ? '' : 'sys:alsa,format:1,'}quality:medium,latency:200`
                : '' ,
      mic: settingsController.getSettings().redirectMicrophone && isMicrophoneReady
                ? `/microphone${window.api.isNewOS ? '' : ':sys:alsa,format:1'}`
                : '',
      printers: settingsController.getSettings().redirectAllPrinters
                ? '/printer'
                : printerController.selectedList.length > 0
                  ? '/printer:' + printerController.selectedList.reduce(
                    (acc, cur) => acc += ' /printer:' + cur + " "
                  )
                  : '',
      predefined: `/network:auto /compression /gfx:rfx /gdi:hw /video /cert:ignore ${!!(rdp_params.os_password) ? "" : "/sec:rdp"}`
    }

  }

  const rdpTunnelKey = `rdp${permId}_${rdp_params.vm}`
  const _createRDPtunnel = () => window.api.openForwardSSHtunnel({
    "tunnelKey": rdpTunnelKey,
    "sshUserPassword": rdp_params.ssh_user_password,
    "sshUsername": rdp_params.ssh_username,
    "publicDns": rdp_params.public_dns,
    "sshPort": rdp_params.ssh_port,
    "remoteIP": rdp_params.private_ip,
    "remotePort": rdp_params.rdp_port,
    "localIP": "127.0.0.1",
    "useNetworkIP": settingsController.getSettings().useNetworkIP
  })
  
  // setup video stream if needed
  let cameraTunnelKey = null
  if (settingsController.getSettings().redirectCamera) {

    add_connection_step('Setting up webcam redirection (may take up to 2 minutes)');
    
    try {
      const adUserId = ad_user_id.get()
      const vmUserId = rdp_params.vmuser || (perm.vmuser && perm.vmuser.id)
      cameraTunnelKey = adUserId ? `adu${adUserId}_${rdp_params.vm}` : `vmu${vmUserId}_${rdp_params.vm}`

      await Promise.all([
        cameraController.controlByContext({
          ...rdp_params,
          tunnelKey: cameraTunnelKey,
          description: perm.vm.descriptor,
          vm_id: rdp_params.vm
        }),

        // also open tunnel for RDP to save time
        _createRDPtunnel()
      ])

    } catch (e) {

      change_connection_step('fail');

    }

    const isPublished = cameraController.isStreamPublished(cameraTunnelKey)
    change_connection_step(isPublished ? 'pass' : 'fail')
    if (isPublished)
      window.api.set_RDP_is_starting(true) // prevent stopping camera redirection during starting RDP period
    else
      cameraTunnelKey = null // reset cameraTunnelKey to skip cleanup next time for RPi 
  }


  add_connection_step('Opening secure tunnel')
  const rdpTunnels = window.api.rdpTunnels()
  let rdpTunnel = settingsController.getSettings().redirectCamera && rdpTunnels[rdpTunnelKey]
  try {
    if (!rdpTunnel) {
      console.log("Create RDP tunnel", rdpTunnelKey)
      await _createRDPtunnel()
    }

    rdpTunnel = (window.api.rdpTunnels())[rdpTunnelKey]
  } catch (e) {
    change_connection_step('fail')
    window.api.set_RDP_is_starting(false) // finished starting RDP (failed)
    throw e
  }

  change_connection_step('pass')


  try {

    if (window.isRPi) {

      add_connection_step('Launching RDP')

      await window.api.openFreeRDP({
        "requires": {
          ...rdp_params,
          
          local_ip: rdpTunnel.localIP,
          local_port: rdpTunnel.localPort
        }, 
        "options": freeRDPoptions
      })

      // clean up previous use RDP
      Object.keys(rdpTunnels).filter(tk => tk !== rdpTunnelKey).forEach(previousTk => {
        console.log("Closing previous rdp tunnel ", previousTk)
        window.api.closeForwardSSHtunnel(previousTk)
      })

      if (previouslRPiCameraTunnelKey && previouslRPiCameraTunnelKey !== cameraTunnelKey) {
        console.warn("Closing previous camera redirection on RPi", previouslRPiCameraTunnelKey)
        cameraController.closeVideoStreamSSHtunnel(previouslRPiCameraTunnelKey)
      }
      previouslRPiCameraTunnelKey = cameraTunnelKey

      // wait few seconds for rdp open
      await new Promise(resolve => setTimeout(resolve, 2000))
      change_connection_step('pass')

    } else {

      await window.api.open_rdp({
        ...rdp_params,
        
        local_ip: rdpTunnel.localIP,
        local_port: rdpTunnel.localPort,
        
        add_connection_step,
        change_connection_step,
        
        useMultimon: settingsController.getSettings().useMultimon,
        useMultimonSelection: settingsController.getSettings().useMultimonSelection,
        multimonSelected: settingsController.getSettings().multimonSelected
      })

    }

  } catch (e) {

    console.error(e);

  }

  setTimeout(() => window.$('#use-rdp').modal('hide'), 500)
  window.api.set_RDP_is_starting(false) // finished starting RDP

};
